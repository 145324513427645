// @file Vue composable that serves as a wrapper for @floating-ui/vue
// Features:
// - Support for Vue 2
// - Window resize listeners
// - Floating auto-update

import type { ComputePositionConfig, Middleware, ReferenceElement } from '@floating-ui/vue'
import { autoUpdate, computePosition, detectOverflow } from '@floating-ui/vue'
import type { Ref } from 'vue'
import { isRef, onMounted, onUnmounted, ref, unref, watch } from 'vue'
import type { CSSProperties } from 'vue/types/jsx'

export type FloatingStyles = Pick<CSSProperties, 'position' | 'top' | 'left'>

interface Styles {
  floatingStyles: Ref<FloatingStyles | undefined>
}

export const useFloating = (
  reference: Ref<ReferenceElement | null | undefined>,
  floating: Ref<HTMLElement | null | undefined>,
  options?: Partial<ComputePositionConfig> | Ref<Partial<ComputePositionConfig>>,
): Styles => {
  const floatingStyles = ref<FloatingStyles>()
  const updateFloatingStylesAsync = async (): Promise<void> => {
    const optionsValue = unref(options)
    if (floating.value != null && reference.value != null) {
      const position = await computePosition(reference.value, floating.value, optionsValue)
      floatingStyles.value = {
        position: position.strategy,
        top: `${position.y}px`,
        left: `${position.x}px`,
      }
    }
  }

  const updateFloatingStyles = (): void => {
    void updateFloatingStylesAsync()
  }

  let cleanUpAutoUpdate: (() => void) | undefined

  watch(
    [reference, floating],
    () => {
      updateFloatingStyles()

      if (floating.value != null && reference.value != null) {
        cleanUpAutoUpdate?.()
        cleanUpAutoUpdate = autoUpdate(reference.value, floating.value, updateFloatingStyles)
      }
    },
    {
      immediate: true,
    },
  )

  if (isRef(options)) {
    watch(options, () => {
      updateFloatingStyles()

      if (floating.value != null && reference.value != null) {
        cleanUpAutoUpdate?.()
        cleanUpAutoUpdate = autoUpdate(reference.value, floating.value, updateFloatingStyles)
      }
    })
  }

  onMounted(() => {
    window.addEventListener('resize', updateFloatingStyles)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateFloatingStyles)
    cleanUpAutoUpdate?.()
  })

  return {
    floatingStyles,
  }
}

/*
 * Custom middlewares
 */

export const shiftUpWhenOverflow = (options: { distanceFromViewportEdges?: number } = {}): Middleware => {
  const { distanceFromViewportEdges = 12 } = options
  return {
    name: 'shiftUpWhenOverflow',
    async fn(state) {
      const overflow = await detectOverflow(state)

      // If overflow is positive, we try to move the element up by the overflow amount in pixels
      const adjustedY = state.y - (overflow.bottom > 0 ? overflow.bottom + distanceFromViewportEdges * 2 : 0)

      // if adjustedY is negative where the floating ui will still overflow out of the viewport,
      // then we will make the floating element scrollable, and set the y-coordinate to 0
      const y = adjustedY < 0 ? 0 : adjustedY

      return {
        x: state.x,
        y,
      }
    },
  }
}
